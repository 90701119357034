<template>
  <div id="gangting-bigScreen" :class="{ fullScreen: isFullScreen }">
    <div class="bigScreen-wrap">
      <div class="head">
        <div class="fullScreenBtn" @click="changeFullScreen">
          <img
            v-if="isFullScreen"
            src="../../assets/images/bigScreen/arrowleft.png"
            alt=""
          />
          <img v-else src="../../assets/images/bigScreen/enlarge.png" alt="" />
          <span>{{ isFullScreen ? "返回" : "全屏" }}</span>
        </div>
        <div>
          <el-select
            v-model="parkingLotId"
            placeholder="请选择停车场"
            @change="changeParking"
            filterable
          >
            <el-option
              v-for="item in parkingLotList"
              :key="item.parkingLotId"
              :label="item.parkingLotName"
              :value="item.parkingLotId"
            >
            </el-option>
          </el-select>
        </div>
        <h1 class="parkingLotName">{{ parkingLotName }}停车场</h1>
      </div>
      <div class="bigScreen-container">
        <bigScreenPassageway ref="bigScreenPassageway" />
        <div class="other">
          <baseInfo />
          <div class="vehicleRecord">
            <h3>车辆记录</h3>
            <ul v-if="info.inChannelId">
              <li>
                <strong>{{ info.plateNum }}</strong>
                <span>车牌号</span>
              </li>
              <li>
                <strong>{{ info.leaseType }}</strong>
                <span>车辆类型</span>
              </li>
              <li>
                <strong>{{ info.subjectName }}</strong>
                <span>车辆归属</span>
              </li>
              <li>
                <strong>{{ info.leaseExpireTime }}</strong>
                <span>过期时间</span>
              </li>
              <li>
                <strong>{{ info.arrivalTime }}</strong>
                <span>入场时间</span>
              </li>
              <li>
                <strong>{{ info.contacts }}</strong>
                <span>联系人</span>
              </li>
            </ul>
            <ul v-else>
              <li>
                <strong>{{ info.plateNum }}</strong>
                <span>车牌号</span>
              </li>
              <li>
                <strong>{{ info.leaseType }}</strong>
                <span>车辆类型</span>
              </li>
              <li>
                <strong>{{ info.payAmount }}</strong>
                <span>支付金额</span>
              </li>
              <li>
                <strong>{{ info.parkingTime }}</strong>
                <span>停车时长</span>
              </li>
              <li>
                <strong>{{ info.departureTime }}</strong>
                <span>出场时间</span>
              </li>
              <li>
                <strong>{{ info.contacts }}</strong>
                <span>联系人</span>
              </li>
            </ul>
          </div>
          <div class="table-tabs">
            <table-tabs ref="gangting-tabs" />
          </div>
          <div class="gangting-passagewayList">
            <passagewayList />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toFullScreen, exitFullscreen } from "@/utils/fullScreen";

export default {
  components: {
    tableTabs: () => import("./table-tabs/table-tabs"),
    passagewayList: () => import("./passagewayList"),
    bigScreenPassageway: () => import("./bigScreen-passageway"),
    baseInfo: () => import("./baseInfo"),
  },
  computed: {
    info() {
      return this.$store.state.GangTing.info;
    },
    passagewayChecked() {
      return this.$store.state.GangTing.passagewayChecked;
    },
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
    ws() {
      return this.$store.state.socket.ws;
    },
    socketInfo() {
      return this.$store.state.socket.info;
    },
  },
  watch: {
    parkingLotList: {
      handler(list) {
        if (list.length) {
          this.parkingLotId = list[0].parkingLotId;
          this.parkingLotName = list[0].parkingLotName;
          this.$store.commit("GangTing/SET_PARKINGLOTID", this.parkingLotId);
        }
      },
      immediate: true,
    },
    parkingLotId: {
      handler(parkingLotId) {
        if (parkingLotId && this.ws) {
          this.ws.send(
            JSON.stringify({
              tag: "0302",
              sn: 1,
              parkingLotId: [this.parkingLotId],
              type: 0,
            })
          );
        }
      },
      immediate: true,
    },
    ws(state) {
      if (state && this.parkingLotId) {
        this.ws.send(
          JSON.stringify({
            tag: "0302",
            sn: 1,
            parkingLotId: [this.parkingLotId],
            type: 0,
          })
        );
      }
    },
    socketInfo(info) {
      this.$nextTick(() => {
        document.querySelector("body").style = "";
      });
      info && this.socketInfoHandler(info);
    },
  },
  data() {
    return {
      isFullScreen: false,
      parkingLotId: "",
      parkingLotName: "",
    };
  },
  mounted() {
    document.addEventListener(
      "webkitfullscreenchange",
      this.webkitfullscreenchange
    );
  },
  destroyed() {
    document.removeEventListener(
      "webkitfullscreenchange",
      this.webkitfullscreenchange
    );
  },
  methods: {
    webkitfullscreenchange(e) {
      if (!document.webkitIsFullScreen) {
        this.isFullScreen = false;
      }
    },
    socketInfoHandler(res) {
      if (res.tag === "0410" || res.tag === "0411") {
        let checkedList = this.passagewayChecked;
        for (let item of checkedList) {
          if (
            item.channelId ===
            res.data[res.tag === "0410" ? "inChannelId" : "outChannelId"]
          ) {
            if (res.tag === "0410") {
              res.data.arrivalTime = res.data.arrivalTime
                ? this.$moment(res.data.arrivalTime).format(
                    "yyyy-MM-DD HH:mm:ss"
                  )
                : "--";
              res.data.leaseExpireTime = res.data.leaseExpireTime
                ? this.$moment(res.data.leaseExpireTime).format("yyyy-MM-DD")
                : "--";
              res.data.subjectName = res.data.subjectName
                ? res.data.subjectName
                : "--";
            } else {
              res.data.departureTime = res.data.departureTime
                ? this.$moment(res.data.departureTime).format(
                    "yyyy-MM-DD HH:mm:ss"
                  )
                : "--";
              res.data.payAmount =
                res.data.payAmount >= 0 ? res.data.payAmount : "--";
            }
            res.data.contacts = res.data.contacts ?? "--";

            item.info = res.data;
            this.$store.commit("GangTing/SET_PASSAGEWAYCHECKED", [
              ...checkedList,
            ]);
            break;
          }
        }
        this.$store.commit("GangTing/SET_INFO", res.data);
      }
    },
    changeParking(value) {
      this.$store.commit("GangTing/SET_PARKINGLOTID", this.parkingLotId);
      for (const item of this.parkingLotList) {
        if (item.parkingLotId === value) {
          this.parkingLotName = item.parkingLotName;
          return;
        }
      }
    },
    changeFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        toFullScreen();
      } else {
        exitFullscreen();
      }
    },
  },
};
</script>

<style lang="scss">
#gangting-bigScreen {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.fullScreen {
    width: 100vw;
    height: 100vh;
    background-color: $main-background;
    padding: 0.77rem 1.49rem;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .bigScreen-wrap {
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: relative;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-bottom: 1.2rem;

      .fullScreenBtn {
        width: 6.13rem;
        height: 2.44rem;
        text-align: center;
        background: url("../../assets/images/bigScreen/back.png") no-repeat
          center center;
        background-size: contain;
        font-size: 1.25rem;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 60%;
          margin-right: 0.31rem;
        }
      }

      .parkingLotName {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2rem;
      }
    }

    .bigScreen-container {
      width: 100%;
      height: calc(100% - 3.64rem);
      display: flex;
      justify-content: space-between;

      .other {
        width: calc(46% - 2.12rem);
        overflow-y: auto;

        .vehicleRecord {
          width: 100%;
          border-radius: 0.31rem;
          padding: 1.5rem 2.06rem 0 2.06rem;
          box-sizing: border-box;
          background-color: #001f46;
          margin-bottom: 0.63rem;

          h3 {
            display: inline-block;
            font-size: 1.13rem;
            color: white;
            margin-bottom: 3rem;
            font-weight: 500;
            position: relative;
            z-index: 2;

            &::after {
              content: "";
              position: absolute;
              bottom: -0.15rem;
              left: -0.15rem;
              z-index: -1;
              width: calc(100% + 0.3rem);
              height: 0.38rem;
              background-color: #00cec1;
            }
          }

          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            box-sizing: border-box;
            padding: 0 1.7rem;

            li {
              width: 33.33%;
              box-sizing: border-box;
              padding: 0 1rem;
              margin-bottom: 3.5rem;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                right: 0;
                display: inline-block;
                width: 0.06rem;
                height: 1.63rem;
                background-color: rgba(186, 186, 186, 1);
              }

              &:nth-child(3),
              &:last-child {
                &:after {
                  display: none;
                }
              }

              strong {
                display: inline-block;
                width: 100%;
                text-align: center;
                color: white;
                font-weight: normal;
                margin-bottom: 1.13rem;
                white-space: nowrap;
              }

              span {
                display: inline-block;
                width: 100%;
                text-align: center;
                color: rgba(255, 255, 255, 0.62);
              }
            }
          }
        }

        .table-tabs,
        .gangting-passagewayList {
          width: 100%;
          border-radius: 0.31rem;
          padding: 1.5rem 2.06rem;
          box-sizing: border-box;
          background-color: #001f46;
          margin-bottom: 0.63rem;
        }
      }
    }
  }
}
</style>
